import React from "react"
import { IconWrapper, IconPathStroke } from "shared/Styles/Icons"

const Parallelogram = ({ width, height, color }) => (
  <IconWrapper
    width={width}
    height={height}
    viewBox="0 0 271 579"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <IconPathStroke
      color={color}
      d="M1 417.134V1L270 132.968V355.484V578L1 417.134Z"
      stroke="#D7D7D7"
    />
  </IconWrapper>
)

export { Parallelogram }
