import React from "react"
import { IconWrapper, IconPathStroke } from "shared/Styles/Icons"

const ParallelogramWide = ({ width, height, color }) => (
  <IconWrapper
    width={width}
    height={height}
    viewBox="0 0 389 459"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <IconPathStroke
      color={color}
      d="M1 251.5V1L388 191.062V376V457.181L1 251.5Z"
      stroke="#D7D7D7"
    />
  </IconWrapper>
)

export { ParallelogramWide }
